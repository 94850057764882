<template>
    <v-main class="main-bg-aroma">
        <div
            class="cookie-notification"
            v-if="cookieShow"
        >
            <p>
                Этот сайт использует cookie и другие технологии для сбора информации технического характера. Подробнее о
                <router-link :to="{name: 'Cookies'}" target="_blank">
                    файлах cookie
                </router-link>
            </p>
            <v-btn
                color="gray"
                class="cookie-accept"
                @click="cookieAccept"
            >
                Хорошо
            </v-btn>
        </div>

        <div class="aroma-main-block">
            <section class="main-section">
              <img src="/img/aroma/liana-left.png" class="left-liana-main-img" alt="">
              <img src="/img/aroma/liana-right.png" class="right-liana-main-img" alt="">
              <div class="main-content">
                <h1 class="aroma-main-h1">
                  Дыхание природы для <br />
                  гармонии души и тела
                </h1>
                <v-row class="row-video-text">
                  <v-col cols="12" sm="7" class="aroma-main-video">
                    <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;">
                      <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
                        <iframe
                          src="https://fast.wistia.net/embed/iframe/jxil363bw6?seo=false&videoFoam=true"
                          title="видео на гл стр Video"
                          muted
                          autoplay="true"
                          allow="autoplay; fullscreen"
                          allowtransparency="true"
                          frameborder="0"
                          scrolling="no"
                          class="wistia_embed"
                          name="wistia_embed"
                          msallowfullscreen
                          width="100%"
                          height="100%"
                        ></iframe>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="5" class="aroma-main-text">
                    <h3>Ароматерапия</h3>
                    <p>— метод воздействия на физическое и психологическое состояние организма при помощи ароматов с научно доказанной эффективностью.</p>
                  </v-col>
                </v-row>
              </div>              
            </section>

            <section class="what-section">
              <h2 class="aroma-h2-title">Ты получишь:</h2>
              <div style="position: relative" class="pt-5 pb-7">
                <v-row class="what-content">
                  <v-col cols="12" sm="6" class="aroma-check-block-left">
                    <p>
                      <img src="/img/aroma/check.png" class="aroma-check" alt="">
                      Полную трансформацию души и тела
                    </p>
                    <p>
                      <img src="/img/aroma/check.png" class="aroma-check" alt="">
                      Улучшение работы органов дыхания и ЖКТ
                    </p>
                    <p>
                      <img src="/img/aroma/check.png" class="aroma-check" alt="">
                      Активизацию когнитивных способностей мозга
                    </p>
                    <p>
                      <img src="/img/aroma/check.png" class="aroma-check" alt="">
                      Нормализацию давления и гормонального баланса
                    </p>
                    <p>
                      <img src="/img/aroma/check.png" class="aroma-check" alt="">
                      Восстановление и сохранение кожи и волос
                    </p>
                  </v-col>
                  <v-col cols="12" sm="6" class="aroma-check-block-right">
                    <p>
                      <img src="/img/aroma/check.png" class="aroma-check" alt="">
                      Уютную атмосферу для твоего дома
                    </p>
                    <p>
                      <img src="/img/aroma/check.png" class="aroma-check" alt="">
                      Возможность Создать себе любое настроение
                    </p>
                    <p>
                      <img src="/img/aroma/check.png" class="aroma-check" alt="">
                      Защиту от вирусов, бактерий и негативной энергии
                    </p>
                    <p>
                      <img src="/img/aroma/check.png" class="aroma-check" alt="">
                      Восстановление эмоционального равновесия
                    </p>
                    <p>
                      <img src="/img/aroma/check.png" class="aroma-check" alt="">
                      Повышение иммунитета, очищение от токсинов
                    </p>
                  </v-col>
                </v-row>
                <h3 class="aroma-h3">
                  ПРИКОСНИСЬ К СИЛЕ <br />
                  НАТУРАЛЬНЫХ ЭФИРНЫХ МАСЕЛ
                </h3>
                <div style="text-align: center;">
                  <button @click="scrollToForm()"><img src="/img/aroma/btn-touch.png" alt=""></button>
                </div>
                <div class="aroma-video-bg">
                  <video class="aroma-bg-video-hosted aroma-html5-video" loop muted autoplay>
                    <source type="video/mp4" src="/video/aroma/aroma-bg-video.mp4">
                  </video>
                </div>
              </div>
            </section>

            <section class="welcome-section">
              <img class="aroma-tree-left-img" src="/img/aroma/tree-left.png" alt="">
              <h2 class="aroma-h2-title">
                ДОБРО ПОЖАЛОВАТЬ В <br />
                АРОМАТИЧЕСКОЕ ПУТЕШЕСТВИЕ
              </h2>
              <div class="aroma-video">
                <div class="thumb-wrap mt-6">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/E2uh77UCL6U?si=ODN6Hylxhrj1BANc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
              </div>
              <h3 class="welcome-h3">ДЛЯ СБОРА СЫРЬЯ МЫ ВЫБРАЛИ САМЫЕ ЛУЧШИЕ МЕСТА НА ПЛАНЕТЕ</h3>
            </section>

            <section class="argentina-section">
              <img class="arg-img-aroma" src="/img/aroma/aroma-argentina.png" alt="">
              <h3 class="h3-title-l">Лимон и Апельсин - Аргентина</h3>
              <h2 class="h2-title-red">МОТИВАЦИЯ И ТОНУС</h2>
              <v-row class="arg-row-block">
                <v-col cols="4">
                  <img class="aroma-arg-pic-1" src="/img/aroma/argentina-pic-1.png" alt="">
                </v-col>
                <v-col cols="8" class="arg-text-block">
                  <p class="aroma-p">
                    <strong>Аргентина - волшебная страна, где страсть к танго соединяется с дикой природой Патагонии.</strong>
                  </p>                
                  <p class="aroma-p">
                    Где любовь к футболу наполняет каждое сердце. Открой для себя природные водопады Игуасу.
                    Почувствуй взрывные вкусы и волнующий ритм, который навсегда оставит след в сердце.
                  </p>
                  <v-row>
                    <v-col cols="7">
                      <img class="aroma-arg-pic-2" src="/img/aroma/argentina-pic-2.png" alt="">
                    </v-col>
                    <v-col cols="5">
                      <img class="aroma-arg-pic-3" src="/img/aroma/argentina-pic-3.png" alt="">
                    </v-col>
                  </v-row>
                </v-col>  
              </v-row>
              <v-row class="arg-row-mobile">                
                <v-col cols="3">
                  <img class="arg-img-mobile" src="/img/aroma/aroma-argentina.png" alt="">
                </v-col>
                <v-col cols="9">
                  <p class="aroma-p">
                    <strong>Аргентина - волшебная страна, где страсть к танго соединяется с дикой природой Патагонии.</strong>
                  </p>
                </v-col>  
              </v-row>
              <p class="aroma-p arg-row-mobile">
                Где любовь к футболу наполняет каждое сердце. Открой для себя природные водопады Игуасу.
                Почувствуй взрывные вкусы и волнующий ритм, который навсегда оставит след в сердце.
              </p>
              <v-row class="arg-row-mobile">
                <v-col cols="7">
                  <img class="aroma-arg-pic-2" src="/img/aroma/argentina-pic-2.png" alt="">
                </v-col>
                <v-col cols="5">
                  <img class="aroma-arg-pic-3" src="/img/aroma/argentina-pic-3.png" alt="">
                </v-col>
              </v-row>
            </section>

            <section class="italy-section">
              <h3 class="h3-title-l">Бергамот - Италия</h3>
              <h2 class="h2-title-green">ВОССТАНОВЛЕНИЕ И СПОКОЙСТВИЕ</h2>              
              <v-row class="italy-row-block">
                <v-col cols="3" style="position: relative;">
                  <img class="italy-aroma-img" src="/img/aroma/aroma-italy.png" alt="">
                </v-col>
                <v-col cols="4">
                  <img class="aroma-it-pic-1" src="/img/aroma/italy-pic-1.png" alt="">
                </v-col>
                <v-col cols="5">
                  <img class="aroma-it-pic-2" src="/img/aroma/italy-pic-2.png" alt="">
                </v-col>
              </v-row>
              <v-row class="italy-row">
                <v-col cols="9">
                  <div>
                    <p class="aroma-p">
                      <strong>Италия - воплощение искусства и вкуса.</strong>
                    </p>
                    <p class="aroma-p">
                      От Рима с его древними руинами до Венеции с ее романтичными каналами, каждый уголок этой страны источает магию.
                      Итальянская кухня соблазняет своими ароматами, а исторические памятники привлекают своей красотой.
                    </p>
                  </div>
                </v-col>
                <v-col cols="3">
                  <img class="aroma-it-pic-3" src="/img/aroma/italy-pic-3.png" alt="">
                </v-col>
              </v-row>

              <v-row class="italy-row-mobile">                
                <v-col cols="3">
                  <img class="italy-aroma-img" src="/img/aroma/aroma-italy.png" alt="">
                </v-col>
                <v-col cols="9">
                  <p class="aroma-p">
                    <strong>Италия - воплощение искусства и вкуса.</strong>
                  </p>
                  <p class="aroma-p">
                    От Рима с его древними руинами до Венеции с ее романтичными каналами, каждый уголок этой страны источает магию.
                      Итальянская кухня соблазняет своими ароматами, а исторические памятники привлекают своей красотой.
                  </p>
                </v-col>  
              </v-row>              
              <v-row class="italy-row-mobile">
                <v-col cols="6">
                  <img class="aroma-it-pic-1" src="/img/aroma/italy-pic-1.png" alt="">
                </v-col>
                <v-col cols="6">
                  <img class="aroma-it-pic-2" src="/img/aroma/italy-pic-2.png" alt="">
                </v-col>
              </v-row>
            </section>

            <section class="portugal-section">
              <img class="port-aroma-img" src="/img/aroma/aroma-portugal.png" alt="">
              <h3 class="h3-title-l">Эвкалипт - Португалия</h3>
              <h2 class="h2-title-teal">ЭНЕРГИЯ И МОЩНЫЙ СТАРТ</h2>    
              <v-row class="port-row-block">
                <v-col cols="4">
                  <img class="aroma-port-pic-1" src="/img/aroma/portugal-pic-1.png" alt="">
                </v-col>
                <v-col cols="8" class="port-text-block">
                  <p class="aroma-p">
                    <strong>Португалия - страна, где солнце обнимает океан, а история пронизывает каждый уголок.</strong>
                  </p>
                  <p class="aroma-p">
                    Открой для себя волшебство Синтры с ее загадочными замками и садами, прикоснись к культурному наследству в узких улочках Порту.
                    Страна, которая обещает тебе незабываемые впечатления и привилегию прикоснуться к своей красоте и наследию.
                  </p>
                  <v-row>
                    <v-col cols="6">
                      <img class="aroma-port-pic-2" src="/img/aroma/portugal-pic-2.png" alt="">
                    </v-col>
                    <v-col cols="6">
                      <img class="aroma-port-pic-3" src="/img/aroma/portugal-pic-3.png" alt="">
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="portugal-row-mobile">                
                <v-col cols="3">
                  <img class="port-aroma-mobile" src="/img/aroma/aroma-portugal.png" alt="">
                </v-col>
                <v-col cols="9">
                  <p class="aroma-p">
                    <strong>Португалия - страна, где солнце обнимает океан, а история пронизывает каждый уголок.</strong>
                  </p>                  
                </v-col>  
              </v-row>   
              <p class="aroma-p portugal-row-mobile">
                Открой для себя волшебство Синтры с ее загадочными замками и садами, прикоснись к культурному наследству в узких улочках Порту.
                Страна, которая обещает тебе незабываемые впечатления и привилегию прикоснуться к своей красоте и наследию.
              </p>           
              <v-row class="portugal-row-mobile">
                <v-col cols="6">
                  <img class="aroma-port-pic-2" src="/img/aroma/portugal-pic-2.png" alt="">
                </v-col>
                <v-col cols="6">
                  <img class="aroma-port-pic-3" src="/img/aroma/portugal-pic-3.png" alt="">
                </v-col>
              </v-row>
            </section>

            <section class="india-section">
              <h3 class="h3-title-l">Мята перечная - Индия</h3>
              <h2 class="h2-title-dark-green">АКТИВАЦИЯ</h2>
              <v-row class="india-row-block">
                <v-col cols="6" style="position: relative;">
                  <img class="ind-aroma-img" src="/img/aroma/aroma-india.png" alt="">
                </v-col>
                <v-col cols="6">
                  <img class="aroma-ind-pic-2" src="/img/aroma/india-pic-2.png" alt=""> 
                </v-col>
              </v-row>
              <v-row class="india-row">
                <v-col cols="6">
                  <img class="aroma-ind-pic-1" src="/img/aroma/india-pic-1.png" alt="">
                </v-col>
                <v-col cols="6">
                  <p class="aroma-p">
                    <strong>Индия - страна, где тысячелетняя культура и духовность окутывают каждый шаг.</strong>
                  </p>
                  <p class="aroma-p">
                    Открой для себя магию Тадж-Махала, ощути мощь толпы на улицах Дели.
                    Живописные места и роскошные храмы оживят твои чувства. Индия - страна, которая проникает в твою душу, оставляя незабываемые впечатления.
                  </p>
                </v-col>
              </v-row>

              <v-row class="india-row-mobile">                
                <v-col cols="3">
                  <img class="india-aroma-mobile" src="/img/aroma/aroma-india.png" alt="">
                </v-col>
                <v-col cols="9">
                  <p class="aroma-p">
                    <strong>Индия - страна, где тысячелетняя культура и духовность окутывают каждый шаг.</strong>
                  </p>                  
                </v-col>  
              </v-row>   
              <p class="aroma-p india-row-mobile">
                Открой для себя магию Тадж-Махала, ощути мощь толпы на улицах Дели.
                    Живописные места и роскошные храмы оживят твои чувства. Индия - страна, которая проникает в твою душу, оставляя незабываемые впечатления.
              </p>           
              <v-row class="india-row-mobile">
                <v-col cols="6">
                  <img class="aroma-ind-pic-1" src="/img/aroma/india-pic-1.png" alt="">
                </v-col>
                <v-col cols="6">
                  <img class="aroma-ind-pic-2" src="/img/aroma/india-pic-2.png" alt="">
                </v-col>
              </v-row>
            </section>

            <section class="bulgaria-section">
              <h3 class="h3-title-l">Масло лаванды - Болгария</h3>
              <h2 class="h2-title-purple">РАССЛАБЛЕНИЕ</h2>
              <v-row class="bulgaria-row-block">
                <v-col cols="9">
                  <p class="aroma-p">
                    Отправляйся на побережье Черного моря, где золотистый песок и прозрачные воды ждут тебя для отдыха и релаксации.
                    <strong>Болгария - где солнце, пляжи и традиции сливаются в единое волшебство.</strong>
                  </p>
                  <p class="aroma-p">
                    Страна, которая обещает тебе не только отличный отдых, но и восхитительные культурные и природные достопримечательности.
                  </p>
                </v-col>
                <v-col cols="3" style="position: relative;">
                  <img class="bul-aroma-img" src="/img/aroma/aroma-bulgaria.png" alt="">      
                </v-col>
              </v-row>
              <v-row class="bulgaria-row">
                <v-col cols="4">
                  <img class="aroma-bul-pic-1" src="/img/aroma/bulgaria-pic-1.png" alt="">
                </v-col>
                <v-col cols="4" class="bul-img-center">
                  <img class="aroma-bul-pic-2" src="/img/aroma/bulgaria-pic-2.png" alt=""> 
                </v-col>
                <v-col cols="4">
                  <img class="aroma-bul-pic-3" src="/img/aroma/bulgaria-pic-3.png" alt=""> 
                </v-col>
              </v-row>

              <v-row class="bulgaria-row-mobile">
                <v-col cols="9">
                  <p class="aroma-p">
                    Отправляйся на побережье Черного моря, где золотистый песок и прозрачные воды ждут тебя для отдыха и релаксации.
                    <strong>Болгария - где солнце, пляжи и традиции сливаются в единое волшебство.</strong>
                  </p>                  
                </v-col>
                <v-col cols="3" style="position: relative;">
                  <img class="bul-aroma-img" src="/img/aroma/aroma-bulgaria.png" alt="">      
                </v-col>
              </v-row>
              <p class="aroma-p bulgaria-row-mobile">
                Страна, которая обещает тебе не только отличный отдых, но и восхитительные культурные и природные достопримечательности.
              </p>
              <v-row class="bulgaria-row-mobile">
                <v-col cols="4">
                  <img class="aroma-bul-pic-1" src="/img/aroma/bulgaria-pic-1.png" alt="">
                </v-col>
                <v-col cols="4" class="bul-img-center">
                  <img class="aroma-bul-pic-2" src="/img/aroma/bulgaria-pic-2.png" alt=""> 
                </v-col>
                <v-col cols="4">
                  <img class="aroma-bul-pic-3" src="/img/aroma/bulgaria-pic-3.png" alt=""> 
                </v-col>
              </v-row>
            </section>

            <section class="spain-section">
              <h3 class="h3-title-l">Тимьян - Испания</h3>
              <h2 class="h2-title-darken-green">ФОКУС ВНИМАНИЯ</h2>
              <v-row class="spain-row-block">
                <v-col cols="3" style="position: relative;">
                  <img class="spain-aroma-img" src="/img/aroma/aroma-spain.png" alt="">
                </v-col>
                <v-col cols="9">
                  <p class="aroma-p">
                    <strong>Испания - страна, где старинная архитектура переплетается с пышными пляжами и живописными ландшафтами.</strong>
                  </p>
                  <p class="aroma-p">
                    Открой для себя богатую историю и культуру, насладись красивыми закатами.
                    Испания - страна, которая пленит сердце своим разнообразием и гостеприимством, и тебя непременно охватит желание вернуться снова и снова.
                  </p>
                </v-col>
              </v-row>
              <v-row class="spain-row">
                <v-col cols="3">
                  <img class="aroma-spain-pic-1" src="/img/aroma/spain-pic-1.png" alt="">
                </v-col>
                <v-col cols="4" class="spain-img-center">
                  <img class="aroma-spain-pic-2" src="/img/aroma/spain-pic-2.png" alt=""> 
                </v-col>
                <v-col cols="5">
                  <img class="aroma-spain-pic-3" src="/img/aroma/spain-pic-3.png" alt=""> 
                </v-col>
              </v-row>

              <v-row class="spain-row-mobile">
                <v-col cols="3" style="position: relative;">
                  <img class="spain-aroma-img" src="/img/aroma/aroma-spain.png" alt="">
                </v-col>
                <v-col cols="9">
                  <p class="aroma-p">
                    <strong>Испания - страна, где старинная архитектура переплетается с пышными пляжами и живописными ландшафтами.</strong>
                  </p>                  
                </v-col>
              </v-row>
              <p class="aroma-p spain-row-mobile">
                Открой для себя богатую историю и культуру, насладись красивыми закатами.
                Испания - страна, которая пленит сердце своим разнообразием и гостеприимством, и тебя непременно охватит желание вернуться снова и снова.
              </p>
              <v-row class="spain-row-mobile">
                <v-col cols="3">
                  <img class="aroma-spain-pic-1" src="/img/aroma/spain-pic-1.png" alt="">
                </v-col>
                <v-col cols="4" class="spain-img-center">
                  <img class="aroma-spain-pic-2" src="/img/aroma/spain-pic-2.png" alt=""> 
                </v-col>
                <v-col cols="5">
                  <img class="aroma-spain-pic-3" src="/img/aroma/spain-pic-3.png" alt=""> 
                </v-col>
              </v-row>
            </section>

            <section class="morocco-section">
              <h3 class="h3-title-l">Кедр - Марокко</h3>
              <h2 class="h2-title-darken-red">ЗАЗЕМЛЕНИЕ</h2>
              <v-row class="morocco-row-block">
                <v-col cols="4">
                  <img class="aroma-moc-pic-1" src="/img/aroma/morocco-pic-1.png" alt="">
                  <img class="aroma-moc-pic-2" src="/img/aroma/morocco-pic-2.png" alt=""> 
                </v-col>
                <v-col cols="5">
                  <p class="aroma-p">
                    Отправляйся в узкие улочки Марракеша, где запахи специй и звуки рынка пробуждают все чувства.
                  </p>
                  <p class="aroma-p">
                    Погрузись в красоту Сахары, где золотистые дюны и бескрайние пустыни заставят тебя забыть о реальности.
                    Открой для себя историческое наследие Марокко, проникнись духом гостеприимства марокканского народа.
                  </p>
                </v-col>
                <v-col cols="3">
                  <img class="mor-aroma-img" src="/img/aroma/aroma-morocco.png" alt="">                       
                  <img class="aroma-moc-pic-3" src="/img/aroma/morocco-pic-3.png" alt=""> 
                </v-col>
              </v-row>
              <v-row class="morocco-row-mobile" style="position: relative;">                
                <v-col cols="9">
                  <p class="aroma-p">
                    Отправляйся в узкие улочки Марракеша, где запахи специй и звуки рынка пробуждают все чувства.
                  </p> 
                </v-col>
                <v-col cols="3">
                  <img class="mor-aroma-img" src="/img/aroma/aroma-morocco.png" alt="">                   
                </v-col>
              </v-row>  
              <p class="aroma-p morocco-row-mobile">
                Погрузись в красоту Сахары, где золотистые дюны и бескрайние пустыни заставят тебя забыть о реальности.
                Открой для себя историческое наследие Марокко, проникнись духом гостеприимства марокканского народа.
              </p>  
              <v-row class="morocco-row-mobile">
                <v-col cols="6">
                  <img class="aroma-moc-pic-1" src="/img/aroma/morocco-pic-1.png" alt="">                  
                </v-col>
                <v-col cols="6">
                  <img class="aroma-moc-pic-2" src="/img/aroma/morocco-pic-2.png" alt="">  
                </v-col>
              </v-row>           
            </section>

            <section class="open-section">
                <h3 class="h3-title">
                  ОТКРОЙ ДВЕРЬ В МИР ЭФИРНЫХ МАСЕЛ, ГДЕ КАЖДЫЙ <br />
                  ВДОХ — ЦЕЛИТЕЛЬНОЕ ПУТЕШЕСТВИЕ
                </h3>
                <div style="text-align: center;">
                  <button @click="scrollToForm()"><img src="/img/aroma/btn-open.png" alt=""></button>
                </div>
            </section>

            <section class="set-section">
                <h3 class="h3-title">
                  ВЫБЕРИ СВОЙ НАБОР, <br />
                  РАСКРЫВАЮЩИЙ ВЕСЬ ПОТЕНЦИАЛ ДУШИ И ТЕЛА
                </h3>
                <v-row class="set-container mb-sm-5 mb-0">
                  <v-col cols="12" sm="6" class="set-block-item">
                    <div class="aroma-video">
                      <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;">
                        <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
                          <iframe
                            src="https://fast.wistia.net/embed/iframe/720ckuk06a?seo=false&videoFoam=true"
                            title="IMG_0469 Video"
                            allow="autoplay; fullscreen"
                            allowtransparency="true"
                            frameborder="0"
                            scrolling="no"
                            class="wistia_embed"
                            name="wistia_embed"
                            msallowfullscreen
                            width="100%"
                            height="100%"
                          ></iframe>
                          </div>
                        </div>
                    </div>
                    <p class="aroma-p-set">
                      Аромакомпозиция LR Soul of Nature Hopeful Soul поможет вам восстановить равновесие, вдохновит вас и подарит ощущение гармонии.
                    </p>
                    <p class="aroma-p-set">
                      Смесь эфирных масел бергамота, розы, римской ромашки и сандалового дерева образует гармоничную композицию
                      и позволяет создать идеальный ритуал для ухода за телом.
                    </p>
                  </v-col>
                  <v-col class="set-block-item">
                    <div class="aroma-video">
                      <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;">
                        <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
                          <iframe 
                            src="https://fast.wistia.net/embed/iframe/vbgig8vavi?seo=false&videoFoam=true"
                            title="IMG_0468 Video"
                            allow="autoplay; fullscreen"
                            allowtransparency="true" 
                            frameborder="0" 
                            scrolling="no" 
                            class="wistia_embed" 
                            name="wistia_embed" 
                            msallowfullscreen 
                            width="100%" 
                            height="100%"
                          ></iframe>
                          </div>
                        </div>
                    </div>
                    <p class="aroma-p-set">LR Soul of Nature Strong Soul – это источник дополнительной движущей силы, жизненной энергии и бодрости духа.</p>
                    <p class="aroma-p-set">
                      Твой ежедневный арома-ритуал с особым ароматом эфирных масел перечной мяты, эвкалипта, тимьяна и кедра поможет восстановить силы и почувствовать
                      готовность преодолевать любые жизненные трудности.
                    </p>
                  </v-col>
                </v-row>
                <v-row class="set-container">
                  <v-col cols="12" sm="6" class="set-block-item">
                    <div class="aroma-video">
                      <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;">
                        <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
                          <iframe 
                            src="https://fast.wistia.net/embed/iframe/h5dbezxp6c?seo=false&videoFoam=true" 
                            title="IMG_0471 Video" 
                            allow="autoplay; fullscreen" 
                            allowtransparency="true" 
                            frameborder="0" 
                            scrolling="no" 
                            class="wistia_embed" 
                            name="wistia_embed" 
                            msallowfullscreen                           
                            width="100%" 
                            height="100%"
                          ></iframe>
                        </div>
                      </div>
                    </div>
                    <p class="aroma-p-set">Душистая аромакомпозиция LR Soul of Nature Cheerful Soul излучает хорошее настроение и позитивную энергию.</p>
                    <p class="aroma-p-set">Эссенции лимона, апельсина, кедра и розы подарят тебе незабываемые моменты, которые поднимут настроение и пробудят чувство счастья.</p>
                  </v-col>
                  <v-col cols="12" sm="6" class="set-block-item">
                    <div class="aroma-video">
                      <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;">
                        <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
                          <iframe
                            src="https://fast.wistia.net/embed/iframe/zyq7luaupd?seo=false&videoFoam=true" 
                            title="IMG_0470 Video" 
                            allow="autoplay; fullscreen" 
                            allowtransparency="true" 
                            frameborder="0" 
                            scrolling="no" 
                            class="wistia_embed" 
                            name="wistia_embed" 
                            msallowfullscreen 
                            width="100%" 
                            height="100%"
                          ></iframe>
                        </div>
                      </div>
                    </div>
                    <p class="aroma-p-set">Аромакомпозиция LR Soul of Nature Relaxed Soul позволит тебе погрузиться в глубокую релаксацию и подарит полноценный сон здесь и сейчас.</p>
                    <p class="aroma-p-set">Композиция эфирных масел бергамота, лаванды, нероли и сандалового дерева поможет тебе создать дома индивидуальный ежедневный ритуал релаксации.</p>
                  </v-col>
                </v-row>
            </section>

            <section class="enjoy-section">
                <h3 class="h3-title">НАСЛАДИСЬ СВОИМИ ЧУВСТВАМИ С АРОМАТАМИ, СОЗДАННЫМИ САМОЙ ПРИРОДОЙ</h3>
                <div style="text-align: center;">
                  <button @click="scrollToForm()"><img src="/img/aroma/btn-enjoy.png" alt=""/></button>
                </div>
                <h3 class="h3-title">РОЖДЕННЫЕ ПРИРОДОЙ ИЗ СЕРДЦА РАСТЕНИЙ</h3>
                <div class="aroma-video">
                  <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;">
                    <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
                      <iframe 
                        src="https://fast.wistia.net/embed/iframe/9q9opywm6h?seo=false&videoFoam=true" 
                        title="как это работает Video" 
                        allow="autoplay; fullscreen" 
                        allowtransparency="true" 
                        frameborder="0" 
                        scrolling="no" 
                        class="wistia_embed" 
                        name="wistia_embed" 
                        msallowfullscreen 
                        width="100%" 
                        height="100%"
                      ></iframe>
                    </div>
                  </div>
                </div>
            </section>

            <section class="choose-section">
                <h3 class="h3-title">ВЫБЕРИ НАПРАВЛЕНИЕ</h3>
                <v-row class="choose-container">
                  <v-col cols="12" sm="6" class="choose-block-item">
                    <img class="arrow-img-left" src="/img/aroma/arrow-left.png" alt="">
                    <h4 class="h4-title">НАРУЖНОЕ ПРИМЕНЕНИЕ</h4>
                    <p class="choose-p">
                      ИСПОЛЬЗОВАНИЕ МАСЕЛ <br />
                      ДЛЯ НАТУРАЛЬНОГО УХОДА ЗА КОЖЕЙ
                    </p>
                    <p class="aroma-p">
                      Эфирные масла - это чудодейственные дары природы, которые не только имеют свои утонченные ароматы, но и обладают способностью буквально оживлять кожу,
                      наполняя ее приятной свежестью, смягчают и питают ее, придавая ей невероятную гладкость и упругость.
                    </p>
                    <p class="p-ars">В ТВОЕМ АРСЕНАЛЕ:</p>
                    <p class="p-ars-check">
                      <img src="/img/aroma/check.png" alt="">
                      ЭФИРНОЕ МАСЛО С РОЛИКОВЫМ АППЛИКАТОРОМ
                    </p>
                    <p class="p-ars-check">
                      <img src="/img/aroma/check.png" alt="">
                      КРЕМ ДЛЯ ТЕЛА
                    </p>
                    <p class="p-ars-check">
                      <img src="/img/aroma/check.png" alt="">
                      КРЕМ ДЛЯ ДУША
                    </p>
                    <p class="p-ars-check">
                      <img src="/img/aroma/check.png" alt="">
                      КОСМЕТИЧЕСКОЕ МАСЛО
                    </p>
                    <div style="text-align: center">
                      <img class="img-ars-left" src="/img/aroma/arsenal-left.png" alt="">
                    </div>
                    <h3 class="h3-choose">
                      ПОДАРИ СВОЕМУ ТЕЛУ <br />
                      НАТУРАЛЬНОЕ БЛАЖЕНСТВО
                    </h3>
                  </v-col>
                  <v-col cols="12" sm="6" class="choose-block-item">
                    <img class="arrow-img-right" src="/img/aroma/arrow-right.png" alt="">
                    <h4 class="h4-title">ИНГАЛЯЦИОННОЕ ВОЗДЕЙСТВИЕ</h4>
                    <p class="choose-p">
                      ИСПОЛЬЗОВАНИЕ МАСЕЛ <br />
                      ДЛЯ АРОМАТИЗАЦИИ ПОМЕЩЕНИЯ
                    </p>
                    <p class="aroma-p">
                      Ощути, как твой дом преображается в оазис здоровья и благополучия, благодаря эффективному сочетанию аромадифузора и натуральных эфирных масел.
                      Ты получишь поддержку для своего физического и эмоционального состояния.
                    </p>
                    <p class="p-ars">В ТВОЕМ АРСЕНАЛЕ:</p>
                    <p class="p-ars-check">
                      <img src="/img/aroma/check.png" alt="">
                      ЭФИРНЫЕ МАСЛА
                    </p>
                    <p class="p-ars-check">
                      <img src="/img/aroma/check.png" alt="">
                      УНИКАЛЬНЫЕ СОЧЕТАНИЯ МАСЕЛ ПОД РАЗЛИЧНЫЕ НУЖДЫ
                    </p>
                    <p class="p-ars-check">
                      <img src="/img/aroma/check.png" alt="">
                      НЕМЕЦКИЙ АРОМАДИФФУЗОР
                    </p>
                    <div style="text-align: center">
                      <img class="img-ars-right" src="/img/aroma/arsenal-right.png" alt="">
                    </div>
                    <h3 class="h3-choose">
                      СОЗДАЙ СВОЮ СОБСТВЕННУЮ <br />
                      СИМФОНИЮ АРОМАТОВ
                    </h3>
                  </v-col>
                </v-row>
                <div style="text-align: center;" class="mt-5">
                  <button @click="scrollToForm()"><img src="/img/aroma/btn-buy.png" alt="" /></button>
                </div>
            </section>

            <section class="lr-section">
                <h3 class="h3-title">ПРИКОСНИСЬ К МАГИИ ЭФИРНЫХ МАСЕЛ</h3>
                <h3 class="h3-title mt-n5">ПРОИЗВОДИТЕЛЬ</h3>
                <div class="aroma-video">
                  <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;">
                    <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
                      <iframe 
                        src="https://fast.wistia.net/embed/iframe/36f2gbutpx?seo=false&videoFoam=true" 
                        title="whatsapp_video_2023-03-02_at_233503" 
                        allow="autoplay; fullscreen" 
                        allowtransparency="true" 
                        frameborder="0" 
                        scrolling="no" 
                        class="wistia_embed" 
                        name="wistia_embed" 
                        msallowfullscreen 
                        width="100%" 
                        height="100%"
                      ></iframe>
                    </div>
                  </div>
                </div>
                <h3 class="h3-title">НЕЖНОСТЬ И ЗАБОТА В КАЖДОЙ КАПЛЕ</h3>
                <h3 class="h3-title mt-n5">МАСЛА ОТ LR ЭТО:</h3>
                <v-row>
                  <v-col cols="12" sm="6" class="lr-block-item">
                    <img class="arrow-img-left" src="/img/aroma/arrow-left.png" alt="">
                    <p class="p-check">
                      <img src="/img/aroma/check.png" alt="">
                      Мгновенный эффект через вдох сразу в мозг
                    </p>
                    <p class="p-check">
                      <img src="/img/aroma/check.png" alt="">
                      35-летний успешный опыт в области ароматов
                    </p>
                    <p class="p-check">
                      <img src="/img/aroma/check.png" alt="">
                      Экологически чистое сырье и 8 моно масел
                    </p>
                    <p class="p-check">
                      <img src="/img/aroma/check.png" alt="">
                      Научно проработанные сочетания и 4 мира ароматов
                    </p>
                    <p class="p-check">
                      <img src="/img/aroma/check.png" alt="">
                      Немецкий керамический ультразвуковой аромадиффузор
                    </p>
                  </v-col>
                  <v-col cols="12" sm="6" class="lr-block-item">
                    <img class="arrow-img-right" src="/img/aroma/arrow-right.png" alt="">
                    <p class="p-check">
                      <img src="/img/aroma/check.png" alt="">
                      Помощь и опыт экспертов и консультантов
                      
                    </p>
                    <p class="p-check">
                      <img src="/img/aroma/check.png" alt="">
                      Полный Набор косметических и гигиенических средств
                    </p>
                    <p class="p-check">
                      <img src="/img/aroma/check.png" alt="">
                      Возможность Создать свою личную композицию
                    </p>
                    <p class="p-check">
                      <img src="/img/aroma/check.png" alt="">
                      Усиление естественных защитных функций организма
                    </p>
                    <p class="p-check">
                      <img src="/img/aroma/check.png" alt="">
                      Снятие напряжения накопленное в течении дня
                    </p>
                  </v-col>
                </v-row>
            </section>

            <section class="reviews-section">
                <h2 class="h2-title">Отзывы</h2>
                <v-carousel
                  class="aroma-carousel"
                  cycle
                  height="400"
                  hide-delimiter-background
                  show-arrows-on-hover
                >
                  <v-carousel-item
                      v-for="(slide, i) in slides"
                      :key="i"
                  >
                    <v-sheet
                      height="100%"
                    >
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center"
                      >
                        <div class="text-h2">
                          <p style="width: 100%; max-width: 450px; height: auto">
                              <img style="width: 100%;" :src=slide.src alt=""/>
                          </p>
                        </div>
                      </v-row>
                    </v-sheet>
                  </v-carousel-item>
                </v-carousel>
                <h3 class="h3-title">СЕКРЕТЫ ПРИРОДЫ РАСКРЫТЫЕ В КАЖДОЙ КАПЛЕ</h3>
                <div style="text-align: center;">
                  <button @click="scrollToForm()"><img src="/img/aroma/btn-order.png" alt=""/></button>
                </div>
            </section>

            <section v-if="userData.id != 127" class="form-section" id="aroma-form">
                <v-container class="text-center">
                    <h4 class="h4-title">ЗАПОЛНИ ДАННЫЕ</h4>
                    <p class="p-aroma-sub-form">
                      получи бесплатную консультацию и помощь в подборе аромамасел
                    </p>
                    <v-responsive
                        class="mx-auto mb-10"
                        max-width="600px"
                    >
                        <v-text-field
                            label="Ваше имя"
                            prepend-icon="mdi-face-man"
                            type="text"
                            v-model.trim="newProspect.name"
                            :error-messages="nameErrors"
                            @input="$v.newProspect.name.$touch()"
                            @blur="$v.newProspect.name.$touch()"
                        />
                        <v-text-field
                            class="cell-input-phone"
                            label="Телефон"
                            prepend-icon="mdi-phone"
                            type="text"
                            v-model.trim="newProspect.phone"
                            :error-messages="phoneErrors"
                            @input="$v.newProspect.phone.$touch()"
                            @blur="$v.newProspect.phone.$touch()"
                        />
                        <div class="invalid-feedback" v-if="errors.phone">
                            {{ errors.phone[0] }}
                        </div>

                        <p style="text-align: center">
                            <v-btn
                                color="#213e30"
                                class="mr-0"
                                @click="addProspectAroma"
                            >
                                Отправить
                            </v-btn>
                        </p>
                        <p class="aroma-privacy-text">
                            Оставляя заявку, вы даете свое
                            <router-link :to="{name: 'Personal'}" target="_blank">Согласие на обработку персональных данных</router-link>
                            и принимаете
                            <router-link :to="{name: 'Policy'}" target="_blank">Политику конфиденциальности</router-link>.
                        </p>
                    </v-responsive>
                    <h3 class="h3-title">ОТКРОЙ НОВЫЕ ГРАНИ СВОЕГО БЛАГОПОЛУЧИЯ И СЧАСТЬЯ</h3>
                </v-container>
            </section>

            <v-footer
                class="justify-center aroma-footer"
                height="100"
            >
                <div class="title font-weight-light text-center">
                    &copy; {{ (new Date()).getFullYear() }} — newlvl.net — независимый партнер LR Health & Beauty
                </div>
            </v-footer>
        </div>

        <v-dialog
            v-model="dialog_prospect"
            width="500"
        >
            <v-card>
                <v-card-title class="headline lighten-2 pb-5" style="background-color: #213e30; color: white">
                    Заявка принята
                </v-card-title>

                <v-card-text>
                    Твой консультант свяжется с вами в ближайшее время
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="#213e30"
                        text
                        @click="dialog_prospect = false"
                    >
                        Хорошо
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-main>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {maxLength, minLength, numeric, required} from "vuelidate/lib/validators";

export default {
    name: "SoulOfNature",
    data: () => ({
        newProspect: {
            name: '',
            phone: '',
            partner: '',
            instrument: 'SoulOfNature',
            step: 'Новый'
        },
        slides: [
            {
                src: '/img/aroma/otzivi/1.png',
            },
            {
                src: '/img/aroma/otzivi/2.png',
            },
            {
                src: '/img/aroma/otzivi/3.png',
            },
            {
                src: '/img/aroma/otzivi/4.png',
            },
            {
                src: '/img/aroma/otzivi/5.png',
            },
            {
                src: '/img/aroma/otzivi/6.png',
            },
            {
                src: '/img/aroma/otzivi/7.png',
            },
            {
                src: '/img/aroma/otzivi/8.png',
            },
        ],
        errors: {},
        dialog_main: false,
        dialog_prospect: false,
        loading: false,
        cookieShow: true,
    }),
    metaInfo: {
        title: 'Soul Of Nature - Аромотерапия',
        meta: [
            { vmid: 'description', property: 'description', content: 'Soul Of Nature - Аромотерапия. Дыхание природы для гармонии души и тела.' },
            { vmid: 'og:title', property: 'og:title', content: 'Soul Of Nature - Аромотерапия' },
            { vmid: 'og:description', property: 'og:description', content: 'Soul Of Nature - Аромотерапия. Дыхание природы для гармонии души и тела.' },
            { vmid: 'og:image', property: 'og:image', content: '/img/aroma/arsenal-left.png' },
        ],
    },
    created() {
        if (!this.$route.query.partner) {
            if (this.$cookies.isKey('newlvl_partner')) {
                const partnerNum = this.$cookies.get('newlvl_partner')
                this.$router.push ({name: 'SoulOfNature', query: { partner: partnerNum }})
            }
        } else {
            this.$cookies.set('newlvl_partner', this.$route.query.partner, '6m')
        }

        if (this.$cookies.isKey('newlvl_cookie')) {
            this.cookieShow = false
        }
    },
    computed: {
        ...mapGetters({
            userData: 'user/getUser'
        }),
        dataUser() {
            return this.userData
        },
        nameErrors() {
            const errors = []
            if (!this.$v.newProspect.name.$dirty) return errors
            !this.$v.newProspect.name.minLength && errors.push('Минимум 2 буквы')
            !this.$v.newProspect.name.maxLength && errors.push('Максимум 30 букв')
            !this.$v.newProspect.name.required && errors.push('Обязательно для заполнения')
            return errors
        },
        phoneErrors() {
            const errors = []
            if (!this.$v.newProspect.phone.$dirty) return errors
            !this.$v.newProspect.phone.numeric && errors.push('Только цифры')
            !this.$v.newProspect.phone.minLength && errors.push('Минимум 5 цифр')
            !this.$v.newProspect.phone.maxLength && errors.push('Максимум 30 цифр')
            !this.$v.newProspect.phone.required && errors.push('Обязательно для заполнения')
            return errors
        }
    },
    methods: {
        ...mapActions({
            addNotification: 'application/addNotification',
            showUser: 'user/showUserPage',
            createProspect: 'user/addClient'
        }),
        cookieAccept() {
            this.cookieShow = false
            this.$cookies.set('newlvl_cookie', 'accept', '12m')
        },
        scrollToForm() {
            let scrollBottom = document.getElementById("aroma-form")
            scrollBottom.scrollIntoView({ behavior: "smooth" })
        },
        addProspectAroma() {
            this.$v.$touch()
            if (!this.$v.$invalid) {
                this.loading = true
                this.createProspect(this.newProspect)
                    .then((response) => {
                        if (response.data && response.data.success) {
                            this.errors = {}
                            this.loading = false
                            this.dialog_prospect = true
                        }
                    })
                    .catch((error) => {
                        switch (error.response.status) {
                            case 422:
                                this.loading = false
                                this.addNotification({
                                    show: true,
                                    text: error.response.data.message,
                                    color: 'error'
                                })
                                this.errors = error.response.data.errors
                                break
                            case 500:
                                this.loading = false
                                this.addNotification({
                                    show: true,
                                    text: error.response.data.message,
                                    color: 'error'
                                })
                                this.errors = error.response.data.errors
                                break
                            default:
                                this.loading = false
                                this.addNotification({
                                    show: true,
                                    text: error.response.data.message,
                                    color: 'error'
                                })
                                this.errors = error.response.data.errors
                                break
                        }
                    })
            } else {
                this.addNotification({
                    show: true,
                    text: 'Ошибка валидации',
                })
            }
        },
    },
    mounted () {
        this.showUser({user: this.$route.query.partner, inst: this.$route.query.inst})
        this.newProspect.partner = this.$route.query.partner
        this.newProspect.inst = this.$route.query.inst
    },
    validations: {
        newProspect: {
            name: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(30)
            },
            phone: {
                required,
                numeric,
                minLength: minLength(5),
                maxLength: maxLength(30)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "Evolventa";
  src: url('/fonts/Evolventa-Regular.otf');
}
@font-face {
  font-family: "Evolventa-Bold";
  src: url('/fonts/Evolventa-Bold.otf');
}
.p-bold-ev {
    font-family: "Evolventa-Bold";
}
.main-bg-aroma {
    background: url(/img/aroma/bg.png);
    background-color: #f8f0de;
    font-family: "Evolventa";
    color: #333;
}
h1, h2, h3, h4 {
  color: #213e30;
}
.aroma-main-block {  
  overflow-x: hidden;
}
section {
  max-width: 1280px;
  margin: 0 auto;
  padding-right: calc(10px + (20 - 10) * ((100vw - 320px) / (1280 - 320)));
  padding-left: calc(10px + (20 - 10) * ((100vw - 320px) / (1280 - 320)));
}
.what-section {
  max-width: 100%;
  padding-right: 0;
  padding-left: 0;
}
.main-section {
  position: relative;
}
.left-liana-main-img {
  max-width: 280px;
  width: calc(150px + (280 - 150) * ((100vw - 320px) / (1280 - 320)));
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
}
.right-liana-main-img {
  max-width: 280px;
  width: calc(150px + (280 - 150) * ((100vw - 320px) / (1280 - 320)));
  opacity: 0.6;
  position: absolute;
  top: 0;
  right: 0;
}
.main-content {
  max-width: 1100px;
  margin: 0 auto;
  z-index: 1;
  position: relative;
}
.aroma-main-h1 {
  text-align: center;
  font-size: calc(18px + (48 - 18) * ((100vw - 320px) / (1280 - 320)));
  text-transform: uppercase;
  line-height: 110%;
  padding-top: calc(20px + (50 - 20) * ((100vw - 320px) / (1280 - 320)));
  padding-bottom: calc(20px + (50 - 20) * ((100vw - 320px) / (1280 - 320)));
}
.row-video-text {
  
}
.aroma-main-video {
  
}
.aroma-video-bg {
    height: 100% !important;
    width: 100% !important;
    top: 0;
    left: 0;
    position: absolute !important;
    overflow: hidden;
    z-index: 0;
    direction: ltr;
    margin: 0 !important;
    max-height: 100vh !important;
    -webkit-transition: opacity 1s;
    -o-transition: opacity 1s;
    transition: opacity 1s;
    pointer-events: none;
    opacity: 0.25;
}
.aroma-bg-video-hosted {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100% !important;
    width: 100% !important;
    max-height: 100vh !important;
    margin: 0 !important;
}
.aroma-html5-video {
    -o-object-fit: cover;
    object-fit: cover;
}
.aroma-main-text {

}
.aroma-main-text h3 {
  font-size: calc(18px + (36 - 18) * ((100vw - 320px) / (1280 - 320)));
  text-transform: uppercase;
  text-align: center;
  padding-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1280 - 320)));
  padding-bottom: calc(10px + (20 - 20) * ((100vw - 320px) / (1280 - 320)));
}
.aroma-main-text p {
  font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1280 - 320)));
  font-weight: 300;
  line-height: 150%;
  // text-align: justify;
  padding: 0 10px;
}
.what-section {
  
}
.what-content {
  max-width: 1100px;
  margin: 0 auto;
}
.aroma-h2-title {
  font-size: calc(18px + (48 - 18) * ((100vw - 320px) / (1280 - 320)));
  text-transform: uppercase;
  text-align: center;
  padding-top: calc(20px + (40 - 20) * ((100vw - 320px) / (1280 - 320)));
  padding-bottom: calc(15px + (20 - 20) * ((100vw - 320px) / (1280 - 320)));
}
.aroma-check-block-left {
  
}
.aroma-check-block-right {
  
}
.aroma-check-block-left p, .aroma-check-block-right p {
  font-size: calc(16px + (18 - 16) * ((100vw - 320px) / (1280 - 320)));
  position: relative;
  padding-left: calc(25px + (30 - 25) * ((100vw - 320px) / (1280 - 320)));;
}
.aroma-check-block-left img, .aroma-check-block-right img {
  width: calc(20px + (25 - 20) * ((100vw - 320px) / (1280 - 320)));
  position: absolute;
  top: 0;
  left: 0;
}
.aroma-h3 {
  font-size: calc(18px + (36 - 18) * ((100vw - 320px) / (1280 - 320)));
  text-transform: uppercase;
  text-align: center;
  line-height: 110%;
  padding-top: calc(10px + (40 - 10) * ((100vw - 320px) / (1280 - 320)));
  padding-bottom: calc(20px + (40 - 20) * ((100vw - 320px) / (1280 - 320)));
}
.welcome-section {
  position: relative;
}
.aroma-tree-left-img {
  width: calc(50px + (100 - 50) * ((100vw - 320px) / (1280 - 320)));
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
}
.aroma-video {
  max-width: 800px;
  margin: 0 auto;
}
.welcome-h3 {
  font-size: calc(18px + (32 - 18) * ((100vw - 320px) / (1280 - 320)));
  text-transform: uppercase;
  text-align: center;
  line-height: 110%;
  padding-top: calc(20px + (50 - 20) * ((100vw - 320px) / (1280 - 320)));
  padding-bottom: calc(20px + (40 - 20) * ((100vw - 320px) / (1280 - 320)));
}
.argentina-section {
  position: relative;
}
.h3-title-l {
  font-size: calc(18px + (42 - 18) * ((100vw - 320px) / (1280 - 320)));
  text-align: center;
}
.h2-title-red {
  font-size: calc(18px + (42 - 18) * ((100vw - 320px) / (1280 - 320)));
  text-transform: uppercase;
  text-align: center;
  color: #c00
}
.arg-img-aroma {
  position: absolute;
  right: calc(0% + (5 - 0) * ((100vw - 320px) / (1280 - 320)));
  max-width: 270px;
  width: calc(100px + (270 - 100) * ((100vw - 320px) / (1280 - 320)));
}
.aroma-p {
  font-size: calc(14px + (26 - 14) * ((100vw - 320px) / (1280 - 320)));
  line-height: 140%;
}
.arg-row-block {
  margin-top: 20px;
}
.arg-text-block {
  margin-top: 20px;
}
.aroma-arg-pic-1 {
  width: 100%;
}
.aroma-arg-pic-2 {
  width: 100%;
}
.aroma-arg-pic-3 {
  width: 100%;
}
.italy-section {
  margin-top: 30px;
  position: relative;
}
.h2-title-green {
  font-size: calc(18px + (42 - 18) * ((100vw - 320px) / (1280 - 320)));
  text-transform: uppercase;
  text-align: center;
  color: rgb(1, 160, 28)
}
.italy-row-block {
  margin-top: 20px;
}
.italy-aroma-img {
  position: absolute;
  top: 0;
  left: calc(0% + (5 - 0) * ((100vw - 320px) / (1280 - 320)));
  max-width: 230px;
  width: calc(100px + (230 - 100) * ((100vw - 320px) / (1280 - 320)));
}
.aroma-it-pic-1 {
  width: 100%;
}
.aroma-it-pic-2 {
  width: 100%;
}
.aroma-it-pic-3 {
  width: 100%;
}
.portugal-section {
  margin-top: 30px;
  position: relative;
}
.h2-title-teal {
  font-size: calc(18px + (42 - 18) * ((100vw - 320px) / (1280 - 320)));
  text-transform: uppercase;
  text-align: center;
  color: teal
}
.port-aroma-img {
  position: absolute;
  right: calc(0% + (5 - 0) * ((100vw - 320px) / (1280 - 320)));
  max-width: 160px;
  width: calc(100px + (160 - 100) * ((100vw - 320px) / (1280 - 320)));
}
.port-row-block {
  margin-top: 20px;
}
.port-text-block {
  margin-top: 20px;
}
.aroma-port-pic-1 {
  width: 100%;
}
.aroma-port-pic-2 {
  width: 100%;
}
.aroma-port-pic-3 {
  width: 100%;
}
.india-section {
  margin-top: 30px;
}
.h2-title-dark-green {
  font-size: calc(18px + (42 - 18) * ((100vw - 320px) / (1280 - 320)));
  text-transform: uppercase;
  text-align: center;
  color: rgb(2, 131, 23)
}
.india-row-block {
  margin-top: 20px;
}
.ind-aroma-img {
  position: absolute;
  left: calc(0% + (5 - 0) * ((100vw - 320px) / (1280 - 320)));
  max-width: 350px;
  width: calc(100px + (350 - 100) * ((100vw - 320px) / (1280 - 320)));
}
.aroma-ind-pic-1 {
  width: 100%;
  position: relative;
  top: -100px;
}
.aroma-ind-pic-2 {
  width: 100%;  
}
.bulgaria-section {
  margin-top: 30px;
}
.bulgaria-row-block {
  margin-top: 20px;
}
.h2-title-purple {
  font-size: calc(18px + (42 - 18) * ((100vw - 320px) / (1280 - 320)));
  text-transform: uppercase;
  text-align: center;
  color: purple
}
.bul-aroma-img {
  position: absolute;
  right: calc(0% + (5 - 0) * ((100vw - 320px) / (1280 - 320)));
  max-width: 250px;
  width: calc(100px + (250 - 100) * ((100vw - 320px) / (1280 - 320)));
}
.aroma-bul-pic-1 {
  width: 100%;
}
.bul-img-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.aroma-bul-pic-2 {
  width: 90%;
}
.aroma-bul-pic-3 {
  width: 100%;
}
.spain-section {
  margin-top: 30px;
}
.spain-row-block {
  margin-top: 20px;
}
.h2-title-darken-green {
  font-size: calc(18px + (42 - 18) * ((100vw - 320px) / (1280 - 320)));
  text-transform: uppercase;
  text-align: center;
  color: #02cdc6;
}
.spain-aroma-img {
  position: absolute;
  left: calc(0% + (5 - 0) * ((100vw - 320px) / (1280 - 320)));
  max-width: 250px;
  width: calc(100px + (250 - 100) * ((100vw - 320px) / (1280 - 320)));
}
.aroma-spain-pic-1 {
  width: 100%;
}
.spain-img-center {
  position: relative;
  top: 30px;
}
.aroma-spain-pic-2 {
  width: 100%;
}
.aroma-spain-pic-3 {
  width: 100%;
}
.morocco-section {
  margin-top: 30px;
}
.morocco-row-block {
  margin-top: 20px;
}
.h2-title-darken-red {
  font-size: calc(18px + (42 - 18) * ((100vw - 320px) / (1280 - 320)));
  text-transform: uppercase;
  text-align: center;
  color: rgb(85, 28, 28)
}
.mor-aroma-img {
  position: relative;
  margin-bottom: 20px;
  margin-left: 30px;
  top: -100px
  // right: calc(0% + (5 - 0) * ((100vw - 320px) / (1280 - 320)));
  // max-width: 250px;
  // width: calc(100px + (250 - 100) * ((100vw - 320px) / (1280 - 320)));
}
.aroma-moc-pic-1 {
  position: relative;
  top: -80px;
  width: 100%;
}
.aroma-moc-pic-2 {
  width: 100%;
  position: relative;
  top: -80px;
}
.aroma-moc-pic-3 {
  width: 90%;
  position: relative;
  top: -80px
}
.open-section {
  margin-top: -50px;
}
.h3-title {
  font-size: calc(18px + (36 - 18) * ((100vw - 320px) / (1280 - 320)));
  text-transform: uppercase;
  text-align: center;
  line-height: 110%;
  padding-top: calc(10px + (40 - 10) * ((100vw - 320px) / (1280 - 320)));
  padding-bottom: calc(20px + (40 - 20) * ((100vw - 320px) / (1280 - 320)));
}
.set-section {
  margin-top: 30px;
}
.set-container {
  max-width: 1200px;
  margin: 0 auto;
}
.set-block-item {
  
}
.set-block-item .aroma-video {
  margin-bottom: 20px;
}
.aroma-p-set {
  font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1280 - 320)));
  line-height: 140%;
}
.enjoy-section {
  
}
.choose-section {
  margin-top: 20px;
}
.choose-container {
  max-width: 1200px;
  margin: 0 auto;
}
.choose-block-item {
  position: relative;
}
.arrow-img-left {
  position: absolute;
  right: 25%;
  top: -25px;
}
.arrow-img-right {
  position: absolute;
  left: 25%;
  top: -25px;
}
.h4-title {
  font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1280 - 320)));
  text-align: center;
  text-transform: uppercase;
  line-height: 110%;
  padding-top: calc(10px + (40 - 10) * ((100vw - 320px) / (1280 - 320)));
  padding-bottom: calc(20px + (20 - 20) * ((100vw - 320px) / (1280 - 320)));
}
.choose-p {
  font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1280 - 320)));
  text-align: center;
}
.choose-section .aroma-p {
  font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1280 - 320)));
  line-height: 140%;
  padding-bottom: 30px;
}
.p-ars {
  font-size: calc(14px + (18 - 14) * ((100vw - 320px) / (1280 - 320)));
  font-weight: 600;
}
.p-ars-check {
  font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1280 - 320)));
  position: relative;
  line-height: 150%;
  padding-left: calc(25px + (30 - 25) * ((100vw - 320px) / (1280 - 320)));;
}
.p-ars-check img {
  width: calc(20px + (25 - 20) * ((100vw - 320px) / (1280 - 320)));
  position: absolute;
  top: 0;
  left: 0;
}
.img-ars-right, .img-ars-left {
  width: calc(300px + (450 - 300) * ((100vw - 320px) / (1280 - 320)));
}
.h3-choose {
  font-size: calc(16px + (28 - 16) * ((100vw - 320px) / (1280 - 320)));
  text-align: center;
  text-transform: uppercase;
  line-height: 110%;
  padding-top: calc(20px + (30 - 20) * ((100vw - 320px) / (1280 - 320)));
  padding-bottom: calc(20px + (20 - 20) * ((100vw - 320px) / (1280 - 320)));
}
.lr-section {
  max-width: 1200px;
  margin: 0 auto;
}
.lr-section .arrow-img-left {
  top: -53px;
  right: 20%;
}
.lr-section .arrow-img-right {
  top: -53px;
  left: 20%;
}
.lr-block-item {
  position: relative;
  margin-top: 30px;
}
.p-check{
  font-size: calc(16px + (20 - 16) * ((100vw - 320px) / (1280 - 320)));
  position: relative;
  line-height: 150%;
  padding-left: calc(25px + (30 - 25) * ((100vw - 320px) / (1280 - 320)));;
}
.p-check img {
  width: calc(20px + (25 - 20) * ((100vw - 320px) / (1280 - 320)));
  position: absolute;
  top: 0;
  left: 0;
}
.reviews-section {
  
}
.h2-title {
  font-size: calc(18px + (42 - 18) * ((100vw - 320px) / (1280 - 320)));
  text-transform: uppercase;
  text-align: center;
  padding-top: calc(20px + (30 - 20) * ((100vw - 320px) / (1280 - 320)));
  padding-bottom: calc(20px + (20 - 20) * ((100vw - 320px) / (1280 - 320)));
}
.aroma-carousel .theme--light.v-sheet {
  background-color: #f8f0de;
}
.aroma-footer {
  background-color: #f8f0de !important;
}
.aroma-privacy-text {
    color: #333 !important;
    font-size: 10px;
    line-height: 15px !important;
    text-align: center;
}
.aroma-privacy-text a {
    color: #333 !important;
}
.p-aroma-sub-form {
  font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1280 - 320)));
}
.morocco-row-mobile {
  display: none;
}
.arg-row-mobile {
  display: none;
}
.italy-row-mobile {
  display: none;
}
.portugal-row-mobile {
  display: none;
}
.india-row-mobile {
  display: none;
}
.bulgaria-row-mobile {
  display: none;
}
.spain-row-mobile {
  display: none;
}
@media (min-width: 1400px) {
  .aroma-main-h1 {
    font-size: 48px;
  }
  .aroma-main-text h3 {
    font-size: 36px;
  }
  .aroma-main-text p {
    font-size: 24px;
  }
  .aroma-h2-title {
    font-size: 48px;
  }
  .aroma-h3 {
    font-size: 36px;
  }
  .aroma-check-block-left p {
    font-size: 18px;
  }
  .h3-title {
    font-size: 36px;
  }
  .h3-choose {
    font-size: 28px;
  }
  .aroma-p {
    font-size: 26px;
  }
  .aroma-p-set {
    font-size: 20px;
  }
  .choose-p {
    font-size: 24px;
  }
  .p-ars-check {
    font-size: 20px;
  }
  .p-check {
    font-size: 20px;
  }
  .choose-section .aroma-p {
    font-size: 20px;
  }
  .welcome-h3 {
    font-size: 32px;
  }
  .h3-title-l {
    font-size: 42px;
  }
  .h2-title-red, .h2-title-green, .h2-title-teal, .h2-title-purple, .h2-title-darken-green, .h2-title-darken-red {
    font-size: 42px;
  }
  .h2-title {
    font-size: 42px;
  }
  .p-aroma-sub-form {
    font-size: 20px;
  }
}
@media(max-width: 1000px) {
  .morocco-section {
    margin-bottom: 30px;
  }
  .aroma-moc-pic-1 {
    top: -40px;
  }
  .aroma-moc-pic-2 {
    top: -40px;
  }
}
@media(max-width: 800px) {
  .aroma-ind-pic-1 {
    top: -40px;
  }
  .morocco-row-block {
    display: none;
  }
  .morocco-row-mobile {
    display: flex;
  }
  .mor-aroma-img {
    top: 0;
    margin: 0;
    width: 100%;
  }
  .aroma-moc-pic-1 {
    top: 0px;
  }
  .aroma-moc-pic-2 {
    top: 0px;
  }
  .aroma-moc-pic-3 {
    top: 100px;
    width: 100%;
  }
  .morocco-section {
    margin-bottom: 60px;
  }
  .arrow-img-left, .arrow-img-right {
    display: none;
  }
  .lr-block-item {
    margin-top: 0;
  }
}
@media(max-width: 600px) {
  .arg-row-block, .arg-img-aroma {
    display: none;
  }
  .arg-row-mobile {
    display: flex;
  }
  .arg-img-mobile {
    width: 100%;
  }
  .italy-row-block, .italy-row {
    display: none;
  }
  .italy-row-mobile {
    display: flex;
  }
  .italy-aroma-img {
    width: 100%;
    position: relative;
    left: 0;
  }
  .port-row-block, .port-aroma-img {
    display: none;
  }
  .portugal-row-mobile {
    display: flex;
  }
  .port-aroma-mobile {
    width: 100%;
  }
  .india-row-block, .india-row {
    display: none;
  }
  .india-row-mobile {
    display: flex;
  }
  .india-aroma-mobile {
    width: 100%;
  }
  .aroma-ind-pic-1 {
    top: 0;
  }
  .bulgaria-row-block, .bulgaria-row {
    display: none;
  }
  .bulgaria-row-mobile {
    display: flex;
  }
  .aroma-bul-pic-2 {
    width: 100%;
  }
  .spain-row-block, .spain-row {
    display: none;
  }
  .spain-row-mobile {
    display: flex;
  }
  .spain-aroma-img {
    top: -20px;
  }
  .choose-block-item .h4-title {
    font-style: italic;
    text-decoration: underline;
  }
}
@media(max-width: 480px) {
  .aroma-check-block-left p, .aroma-check-block-right p {
    line-height: 100%;
  }
}
@media(max-width: 360px) {
  
}
</style>